.rateInputContainer {
    margin-top: 24px;
}

.otcEulaText {
    text-align: left;
margin: 0;
margin-top: 16px;
font-family: 'Noto Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
color: var(--text-color-grey);
}

.otcEulaText-dark {
    text-align: left;
    margin: 0;
    margin-top: 16px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color-grey);
}