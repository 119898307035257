.creditCardCommissionText {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-align: left;
    flex-direction: row;
}

.creditCardCommissionTextBold {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0 0 0 1px;
    text-align: left;
}

.creditCardCommissionTextBold-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0 0 0 1px;
    text-align: left;
}

.creditCardCommissionText-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-align: left;
    flex-direction: row;
}

.creditCardCommissionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 4px 0 0 0;
    text-align: left;
}

.creditCardCommissionTotalText {
    color: var(--text-color-dark);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 0 6px;
    text-align: left;
}