.componentBorderless {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--white);
    height: 100%;
    width: 100%;
}

.componentBorderless-dark {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--backgroundColor-dark-one);
    height: 100%;
    width: 100%;
}

.verificationLogoText {
    margin: 16px 0 0 0;
    text-align: left;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationLogoText-dark {
    margin: 16px 0 0 0;
    text-align: left;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationLastName {
    margin-top: 24px;
}

.verificationCheckBoxGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.verificationCheckBox {
    align-self: flex-start;
}

.innerVerificationCheckBox {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.innerVerificationCheckBoxText {
    text-align: left;
    margin: 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationSumSumText {
    text-align: center;
    margin: 209px 0 0 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationSumSubButton {
    margin-top: 24px;
}

.verificationSumSumPoweredText {
    margin-top: 24px;
    text-align: center;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationWhale {
    margin: 16px 0 0 0;
}

.verificationTestStartLogo {
    margin-top: 376px;
    margin-bottom: 0;
    text-align: center;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationTestStartLogo-dark {
    margin-top: 376px;
    margin-bottom: 0;
    text-align: center;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationTestDescription {
    text-align: center;
    margin-bottom: 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationTestDescription-dark {
    text-align: center;
    margin-bottom: 0;
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationStartTestButton {
    margin-top: 188px;
}

.verificationQuestionNumber {
    text-align: left;
    color: var(--text-light-one);
    margin-bottom: 0;
    margin-top: 16px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationQuestionNumber-dark {
    text-align: left;
    color: var(--text-color-grey-second);
    margin-bottom: 0;
    margin-top: 16px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationQuestion {
    text-align: center;
    color: var(--text-light-one);
    margin-bottom: 0;
    margin-top: 16px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.verificationQuestion-dark {
    text-align: center;
    color: var(--text-dark-one);
    margin-bottom: 0;
    margin-top: 16px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.questionText {
    margin-bottom: 0;
    color:  var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 24px;
}

.questionText-dark {
    margin-bottom: 0;
    color:  var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 24px;
}

.answersDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.answerDiv {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.answerCheckBox {
    margin-right: 16px;
}

.answerText {
    text-align: left;
    margin: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.answerText-dark {
    text-align: left;
    margin: 0;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}