.forgotPasswordText {
    text-align: left;
    color: var(--text-color-link);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 18px 0 0 0;
}

.loginButtonError {
    margin-top: 24px;
}

.loginButton {
    margin-top: 62px;
}

.loginErrorText {
    color: var(--text-color-error);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 18px 0 0 0;
}

.twofaWrongOption {
    color: var(--text-color-error);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 18px 0 0 0;
}

.passwordRecoveryText {
    margin: 0;
    text-align: left;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

.passwordRecoveryText-dark {
    margin: 0;
    text-align: left;
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px
}

.forgotPasswordContainerText {
    margin-top: 24px;
    padding: 16px 24px;
    display: flex;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--backgroundColor-light-second);
}


.forgotPasswordContainerText-dark {
    margin-top: 24px;
    padding: 16px 24px;
    display: flex;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--backgroundColor-dark-second);
}

.modalOtpItemCode {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.modalOtpItemCode-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.otpSelect {
    width: 100%;
    height: 60px;
    margin-top: 24px;
}

.resendCodeText {
    color: var(--text-color-resend);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 18px 0 0 0;
    align-self: flex-start;
}

.resendCodeText-inactive {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 18px 0 0 0;
    align-self: flex-start;
}