.background {
    background: linear-gradient(90deg, var(--gradient-color-start) 0%, var(--text-color-link) 100%);
    height: 150px;
    width: 100%;
    position: absolute;
}

.logo {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 24px;
    position: absolute;
    display: flex;
    flex-direction: row;
}

.tails {
    margin-left: calc(100% - 110px);
    top: -10px;
    position: absolute;
}