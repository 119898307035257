.setupMfCode-item-description {
    margin-bottom: 140px;
    margin-left: 28px;
    margin-right: 20px;
}

.setupMfCode-item-description-text {
    text-align: left;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.setupMfCode-item-description-text-dark {
    text-align: left;
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.setupMfCode-item-secretKey {
    display: flex;
    margin-top: 24px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--backgroundColor-light-second);
}

.setupMfCode-item-secretKey-dark {
    display: flex;
    margin-top: 24px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--backgroundColor-dark-second);
}

.qrCode-image {
    margin-top: 24px;
}

.setupMfCode-item-secretKey-inner {
    width: 100%;
}

.copyButton {
    width: 24px;
    height: 24px;
}

.key {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-align: left;
}

.key {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    text-align: left;
}

.key-text-inner {
    display: flex;
    margin-top: 4px;
    justify-content: space-between;
    flex-direction: row;
}

.key-text {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}


.key-text-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}