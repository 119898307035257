

.exchangeWelcomeText {
    margin: 4px 0 0 0;
    color: var(--text-light-one);
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangeWelcomeText-dark {
    margin: 4px 0 0 0;
    color: var(--text-dark-one);
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangeSwapContainer {
    position: absolute;
    width: calc(100% - 48px);
    top: 148px;
}

.exchangeSwapIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0;
}

.exchangeModalItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 44px;
    padding: 14px 0;
}

.exchangeModalItemFirstContainer {
    display: flex;
    flex-direction: row;
}

.exchangeModalContent {
    overflow: auto;
    margin-top: 16px;
    height: 100%;
}

.exchangeModalContent-empty {
    margin-top: 16px;
    height: 100%;
}

.exchangeModalItemNameContainer {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.exchangeModalEmptyHeader {
    color: var(--text-light-one);
    margin: 8px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangeModalEmptyHeader-dark {
    color: var(--text-dark-one);
    margin: 8px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangeModalEmptyBottom {
    color: var(--text-color-grey);
    margin: 8px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.exchangeModalEmptyBottom-dark {
    color: var(--text-color-grey);
    margin: 8px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}


.exchangeModalEmptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.exchangeCurrencyRowAssetAmount {
    margin: 0;
    margin-right: 5px;
    background: var(--white);
    padding-left: 45px;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.exchangeCurrencyRowAssetAmount-dark {
    margin: 0;
    margin-right: 5px;
    background: var(--backgroundColor-dark-one);
    padding-left: 45px;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.exchangeRate {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.exchangeRatePriceText {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
}

.exchangeRatePriceText-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
}

.exchangeRateText {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin: 0;
}

.exchangeRateText-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin: 0;
}

.exchangeRateWarningText {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin: 4px 0 0 0;
}

.exchangeRateWarningText-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin: 4px 0 0 0;
}

.exchangeConfirmButton {
    margin-top: 24px;
}

.exchangeConfirmContainer {
    margin-top: 24px;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    background: var(--backgroundColor-light-second);
    border-radius: 16px;
}

.exchangeConfirmContainer-dark {
    margin-top: 24px;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    background: var(--backgroundColor-dark-second);
    border-radius: 16px;
}

.exchangeSellText {
    color: var(--text-color-grey);
    text-align: left;
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.exchangeSellText-dark {
    color: var(--text-color-grey-second);
    text-align: left;
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.spanAsymp {
    margin-right: 2px;
    color: var(--text-light-one);
}

.spanAsymp-dark {
    margin-right: 2px;
    color: var(--text-dark-one);
}

.bankWithdrawAmountText {
    max-width: 180px;
    word-wrap: break-word;
    color: var(--text-light-one);
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    margin: 0;
}

.bankWithdrawAmountText-dark {
    max-width: 180px;
    word-wrap: break-word;
    color: var(--text-dark-one);
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    margin: 0;
}

.exchangeConfirmAmount {
    max-width: 180px;
    word-wrap: break-word;
    color: var(--text-light-one);
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}

.exchangeConfirmAmount-dark {
    max-width: 180px;
    word-wrap: break-word;
    color: var(--text-dark-one);
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}

.exchangeConfirmTextRate {
    color: var(--text-light-one);
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.exchangeConfirmTextRate-dark {
    color: var(--text-dark-one);
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.exchangeConfirmText {
    color: var(--text-light-one);
    margin: 0 8px 0 8px;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.exchangeConfirmText-dark {
    color: var(--text-dark-one);
    margin: 0 8px 0 8px;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.exchangeAssetBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.exchangeConfirmCloseButton {
    margin-top: 48px;
}

.exchangeConfirmFirstBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.exchangeConfirmSecondBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.exchangeConfirmAsset {
    width: 18px;
    height: 18px;
    margin: 0;
}

.exchangeDivider {
    margin-top: 16px;
    background: var(--backgroundColor-light-third);
}

.exchangeDivider-dark {
    margin-top: 16px;
    background: var(--backgroundColor-dark-third);
}

.exchangeConfirmThirdBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}

.exchangeConfirmFourthBlock {
    color: var(--text-color-grey);
    margin: 16px 0 0 0;
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.exchangeConfirmFourthBlock-dark {
    color: var(--text-color-grey-second);
    margin: 16px 0 0 0;
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.exchangePaymentLogoContainer {
    margin-top: 108px;
}

.exchangePaymentLogo {
    width: 64px;
    height: 64px;
}

.exchangePaymentHeaderText {
    color: var(--text-light-one);
    margin: 16px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangePaymentHeaderText-dark {
    color: var(--text-dark-one);
    margin: 16px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}


.exchangePaymentBottomText {
    color: var(--text-color-grey);
    margin: 8px 0 0 0;
    text-align: center;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.exchangePaymentBottomText-dark {
    color: var(--text-color-grey);
    margin: 8px 0 0 0;
    text-align: center;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.exchangePaymentFirstInfoContainer {
    margin-top: 108px;
    padding: 16px 20px;
    border-radius: 16px;
    background: var(--backgroundColor-light-second);
    display: flex;
    flex-direction: column;
}

.exchangePaymentFirstInfoContainer-dark {
    margin-top: 108px;
    padding: 16px 20px;
    border-radius: 16px;
    background: var(--backgroundColor-dark-second);
    display: flex;
    flex-direction: column;
}

.exchangePaymentSecondInfoContainer {
    margin-top: 16px;
    padding: 16px 20px;
    border-radius: 16px;
    background: var(--backgroundColor-light-second);
    display: flex;
    flex-direction: column;
}

.exchangePaymentSecondInfoContainer-dark {
    margin-top: 16px;
    padding: 16px 20px;
    border-radius: 16px;
    background: var(--backgroundColor-dark-second);
    display: flex;
    flex-direction: column;
}

.exchangeAssetContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#secondExchangeAssetContainer {
    margin-top: 16px;
}