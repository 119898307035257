.circle-wrapper {
    background: var(--white);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-wrapper-dark {
    background: var(--backgroundColor-dark-one);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-position {
    position: absolute;
}