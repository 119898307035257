.component {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 24px 24px 0 0;
    padding-top: 24px;
    background: var(--white);
    width: 100%;
    margin-top: 78px;
}

.component-dark {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 24px 24px 0 0;
    padding-top: 24px;
    background: var(--backgroundColor-dark-one);
    width: 100%;
    margin-top: 78px;
}

.component-clean {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--white);
    width: 100%;
}

.component-clean-dark {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--backgroundColor-dark-one);
    width: 100%;
}

.innerComponent {
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
    height: 100%;
}

.welcomeText {
    margin: 0 0 24px 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
}

.welcomeText-dark {
    margin: 0 0 24px 0;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
}

.confirmEmailText {
    margin-top: 16px;
    margin-bottom: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
}

.confirmEmailText-dark {
    margin-top: 16px;
    margin-bottom: 0;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
}

.confirmCodeText {
    margin-top: 8px;
    margin-bottom: 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.confirmCodeText-dark {
    margin-top: 8px;
    margin-bottom: 0;
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.codeInputBlock {
    margin-top: 24px;
}

.checkboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.firstCheckBox {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.secondCheckBox {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.thirdCheckBox {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkBox-h1 {
    text-align: left;
    margin: 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.checkBox-h1-dark {
    text-align: left;
    margin: 0;
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.startButton {
    margin-top: 24px;
    height: 56px;
}

.divider {
    border-color: var(--backgroundColor-light-second) !important;
    margin-top: 24px;
}

.divider-dark {
    border-color: var(--backgroundColor-dark-third) !important;
    margin-top: 24px;
}

.selectImg {
    width: 24px;
    height: 24px;
}

.modalItemImg {
    width: 36px;
    height: 36px;
}

.confirmEmailButton {
    margin-top: 24px;
}

.backButton {
    margin-top: 24px;
    height: 56px;
}

.codeInputBlock {
    display: flex;
    flex-direction: column;
}

.pin_input {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex: 1;
}

.pin_input__field {
    width: 51px;
    height: 57px;
    background: var(--backgroundColor-light-second);
    border-radius: 12px;
    border: 1px solid var(--backgroundColor-light-second);
    padding: 0;
    font-size: 16px !important;
    text-align: center;
}

.pin_input__field-dark {
    width: 51px;
    height: 57px;
    background: var(--backgroundColor-dark-second);
    border-radius: 12px;
    color: var(--text-dark-one);
    border: 1px solid var(--backgroundColor-dark-second);
    padding: 0;
    font-size: 16px !important;
    text-align: center;
}

.pin_input__field-dark:focus-visible {
    outline: 2px solid var(--text-color-link);
}

.pin_input__field:focus-visible {
    outline: 2px solid var(--text-color-link);
}

.resendCodeText {
    color: var(--text-color-resend);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 18px;
    margin-bottom: 24px;
    align-self: flex-start;
}

.resendCodeText-dark {
    color: var(--text-color-link);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 18px;
    margin-bottom: 24px;
    align-self: flex-start;
}

.resendCodeText-inactive {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 18px;
    margin-bottom: 24px;
    align-self: flex-start;
}

.resendCodeText-inactive-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 18px;
    margin-bottom: 24px;
    align-self: flex-start;
}


.mobileInputBlock {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-content: space-between;
}

.nameAndPassword {
    margin-top: 16px;
    margin-bottom: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
}

.nameAndPassword-dark {
    margin-top: 16px;
    margin-bottom: 0;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
}

.nameInput {
    margin-top: 24px;
}

.passwordInput input[type='password']::-ms-reveal,
.passwordInput input[type='password']::-ms-clear {
  display: none;
}

.passwordInput {
    margin-top: 24px;
}

.createPasswordHelp {
    margin-top: 4px;
    margin-bottom: 0;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.createPasswordHelp-dark {
    margin-top: 4px;
    margin-bottom: 0;
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.registrationModalComponent {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 90%;
    margin-top: 48px;
    background-color: var(--white);
    border-radius: 24px 24px 0 0;
    padding: 24px 24px 0 24px;
}

.registrationModalComponent-dark {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 90%;
    margin-top: 48px;
    background-color: var(--backgroundColor-dark-one);
    border-radius: 24px 24px 0 0;
    padding: 24px 24px 0 24px;
}

.bankCredentialsModalComponent {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 90%;
    margin-top: 48px;
    background-color: var(--backgroundColor-light-second);
    border-radius: 24px 24px 0 0;
    padding: 24px 24px 0 24px;
}

.bankCredentialsModalComponent-dark {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 90%;
    margin-top: 48px;
    background-color: var(--backgroundColor-dark-one);
    border-radius: 24px 24px 0 0;
    padding: 24px 24px 0 24px;
}

.registrationModalHeader {
    margin: 0 0 19px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.registrationCloseButton {
    width: 24px;
    height: 24px;
    color: var(--text-light-one);
}

.registrationModalHeaderText {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    margin: 0;
}

.registrationModalHeaderText-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    margin: 0;
}

.registrationModalInput {
    position: sticky;
    z-index: 1;
}

.registrationModalContent {
    overflow: auto;
    height: 100%;
}

.registrationModalContent-empty {
    height: 100%;
}

.modalItem {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.countryCodeSelect {
    width: 140px;
    margin-right: 12px;
}

.modalItemLogo {
    display: flex;
    flex-direction: row;
}

.modalItemLogoTextWithoutMargin {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 6px 0 0 0;
}

.modalItemLogoTextWithoutMargin-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 6px 0 0 0;
}

.modalItemLogoText {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 6px 0 0 24px;
}

.modalItemLogoText-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 6px 0 0 24px;
}

.modalItemCode {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.modalItemCode-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.referralCodeText {
    margin-top: 24px;
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.registrationEmailError {
    color: var(--text-color-error);
    margin: 12px 0 0 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.countryCodeSelect-defaultCountry {
    margin: 0;
    background: var(--backgroundColor-grey);
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.verificationConfirmPageContainer {
    margin: 10px 57px 0 57px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.verificationTestDescriptionText {
    text-align: center;
    margin: 0;
    display: flex;
    justify-content: center;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: pre;
}

.verificationTestDescriptionText-dark {
    text-align: center;
    margin: 0;
    display: flex;
    justify-content: center;
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: pre;
}

::marker {
    color: var(--text-color-grey);
}

.verificationConfirmPageText {
    margin: 0;
    color: var(--text-color-grey);
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.verificationConfirmPageText-dark {
    margin: 0;
    color: var(--text-color-grey-second);
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: var(--backgroundColor-button-registration) !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    color: var(--backgroundColor-light-third) !important;
    padding: 9px 9px 9px 0 !important;
}

.MuiFormLabel-asterisk {
    display: none;
}