.bankDepositCredentialContainer {
    margin-top: 16px;
    background: var(--white);
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
}

.bankDepositCredentialContainer-dark {
    margin-top: 16px;
    background: var(--backgroundColor-dark-second);
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
}

.bankDepositCredentialContainerFake {
    margin-top: 16px;
    padding: 16px 24px;
    display: flex;
    background: var(--backgroundColor-light-second);
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
}

.bankDepositCredentialContainerFake-dark {
    margin-top: 16px;
    padding: 16px 24px;
    display: flex;
    background: var(--backgroundColor-dark-one);
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
}

.bankDepositCredentialsHeader {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-align: left;
}

.bankDepositCredentialsHeader-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-align: left;
}

.bankDepositCredentialsHeaderFake-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-align: left;
}

.bankDepositCredentialsHeaderFake {
    color: var(--backgroundColor-light-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-align: left;
}

.bankDepositCredentialsInnerContainer {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.bankDepositCredentialsInnerText {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.bankDepositCredentialsInnerText-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}


.bankDepositCredentialsInnerTextFake {
    color: var(--backgroundColor-light-second);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.bankDepositCredentialsInnerTextFake-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}


.bankDepositCredentialList {
    overflow-y: scroll;
}

.bankDepositCopyIcon {
    width: 24px;
    height: 24px;
    margin: 0;
}

.bankDepositPDFUpperContainer {
    margin-top: 24px;
    border-radius: 12px 12px 0 0;
    background: var(--backgroundColor-light-second);
    padding: 24px 24px 0 24px;
    height: 284px;
}

.bankDepositPDFUpperContainer-dark {
    margin-top: 24px;
    border-radius: 12px 12px 0 0;
    background: var(--backgroundColor-dark-second);
    padding: 24px 24px 0 24px;
    height: 284px;
}

.bankDepositPDFBottomContainer {
    margin: 0;
    border-radius: 0 0 12px 12px;
    background: var(--backgroundColor-light-third);
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.bankDepositPDFBottomContainer-dark {
    margin: 0;
    border-radius: 0 0 12px 12px;
    background: var(--backgroundColor-dark-second);
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bankDepositPDFBottomText {
    color: var(--text-light-one);
    margin: 0 16px 0 0;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.bankDepositPDFBottomText-dark {
    color: var(--text-dark-one);
    margin: 0 16px 0 0;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.bankDepositShareButton {
    width: 24px;
    height: 24px;
    margin: 0;
}