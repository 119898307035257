.error-page-component {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 15px 15px 0 0;
    background: var(--white);
    padding-top: 100px;
    height: 100%;
    width: 100%;
}

.error-background {
    background: linear-gradient(90deg, var(--gradient-color-start) 0%, var(--text-color-link) 100%);
    height: 106px;
    width: 100%;
}

.error-page-item-logo {
    display: flex;
    justify-content: center;
    margin: 40px 0 10px 0;
}

.error-page-item-text-h1 {
    margin: 36px 0 36px 0;
}