.analytics-copied-icon {
    width: 20px;
    height: 20px;
}

.analyticsLogoText {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 0;
    text-align: center;
}

.analyticsLogoText-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 0;
    text-align: center;
}

.analyticsTotalContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.analyticsTotalAmount {
    color: var(--text-light-one);
    text-align: center;
    font-family: Noto Sans, serif;
    margin: 0;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
}

.analyticsTotalAmount-dark {
    color: var(--text-dark-one);
    text-align: center;
    font-family: Noto Sans, serif;
    margin: 0;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
}

.analyticsEqualContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.analyticsEqualText {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    margin: 0 24px 0 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.analyticsEqualText-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    margin: 0 24px 0 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.analyticsEqualAmount {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.analyticsEqualAmount-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.analyticsTotalAmountCurrency {
    color: var(--text-color-grey);
    text-align: center;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    margin-left: 9px;
}


.analyticsTotalAmountCurrency-dark {
    color: var(--text-color-grey-second);
    text-align: center;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    margin-left: 9px;
}

.analyticsAssetText {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 68px 0 0 0;
}

.analyticsAssetText-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 68px 0 0 0;
}

.analyticsTransactionsText {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 36px 0 0 0;
}

.analyticsTransactionsText-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 36px 0 0 0;
}

.analyticsCurrencyRow {
    max-height: 100%;
    height: 72px;
    margin: 0;
    padding: 14px 0;
    display: flex;
    flex-direction: row;
    background: var(--white);
}

.analyticsCurrencyRow-dark {
    max-height: 100%;
    height: 72px;
    margin: 0;
    padding: 14px 0;
    display: flex;
    flex-direction: row;
    background: var(--backgroundColor-dark-one);
}

.analyticsImgContainer {
    display: flex;
    align-items: center;
}

.analyticsImg {
    height: 44px;
    width: 44px;
}

.analyticsCurrencyRowContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 16px;
    background: var(--white);
}

.analyticsCurrencyRowContainer-dark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 16px;
    background: var(--backgroundColor-dark-one);
}

.analyticsCurrencyRowAmount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.analyticsAssetList {
    margin-top: 16px;
}

.analyticsCurrencyRowAssetText {
    color: var(--text-light-one);
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.analyticsCurrencyRowAssetText-dark {
    color: var(--text-dark-one);
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.analyticsCurrencyRowAssetAmount {
    margin: 0;
    background: var(--white);
    position: absolute;
    right: 24px;
    padding-left: 45px;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}


.analyticsCurrencyRowAssetAmount-dark {
    margin: 0;
    background: var(--backgroundColor-dark-one);
    position: absolute;
    right: 24px;
    padding-left: 45px;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.analyticsCurrencyRowUsdt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.analyticsCurrencyRowUsdtText {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.analyticsCurrencyRowUsdtText-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.analyticsCurrencyRowUsdtAmount {
    margin: 0;
    background: var(--white);
    position: absolute;
    right: 24px;
    padding-left: 45px;
    color: var(--text-light-grey-third);
    text-align: right;
    font-size: 14px;
    font-family: Noto Sans, serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.analyticsCurrencyRowUsdtAmount-dark {
    margin: 0;
    background: var(--backgroundColor-dark-one);
    position: absolute;
    right: 24px;
    padding-left: 45px;
    color: var(--text-color-grey-second);
    text-align: right;
    font-size: 14px;
    font-family: Noto Sana, serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.analyticsShowMoreButtonLayout {
    background: var(--backgroundColor-ripple);
}

.analyticsEWalletLayout {
    margin-top: 16px;
    border-radius: 16px;
    background: var(--backgroundColor-light-second);
    height: 56px;
    padding: 4px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.analyticsEWalletLayout-dark {
    margin-top: 16px;
    border-radius: 16px;
    background: var(--backgroundColor-dark-second);
    height: 56px;
    padding: 4px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.analyticsEWalletLayoutText {
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}

.analyticsEWalletLayoutText-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}

.analyticsEWalletLayoutButton {
    border: none;
    width: 24px;
    height: 24px;
    background: var(--white);
}

.analyticsTransactionsList {
    margin-top: 16px;
}

.analyticsTransactionsItem {
    display: flex;
    flex-direction: row;
    padding: 14px 24px;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% - 48px);
}

.analyticsTransactionsItemFirstContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.analyticsTransactionsItemSecondContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.analyticsTransactionsItemDate {
    color: var(--text-color-grey);
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.analyticsTransactionsItemDate-dark {
    color: var(--text-color-grey-second);
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.analyticsTransactionsItemNaming {
    margin: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.analyticsTransactionsItemNaming-dark {
    margin: 0;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.analyticsTransactionsItemStatus {
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 8px;
}

.analyticsTransactionsItemStatus-dark {
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 8px;
}

.analyticsTransactionsItemAmount {
    margin: 10px 0 0 0;
    position: absolute;
    background: var(--white);
    padding-left: 35px;
    right: 24px;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.analyticsTransactionsItemAmount-dark {
    margin: 10px 0 0 0;
    position: absolute;
    background: var(--backgroundColor-dark-one);
    padding-left: 35px;
    right: 24px;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.analyticsTransactionsItemStatusContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.analyticsTransactionsExchangeContainer {
    position: absolute;
    right: 24px;
    display: flex;
    margin-top: 34px;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding: 2px 4px;
    background: var(--backgroundColor-light-second);
    height: 22px;
}

.analyticsTransactionsExchangeContainer-dark {
    position: absolute;
    right: 24px;
    display: flex;
    margin-top: 34px;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding: 2px 4px;
    background: var(--backgroundColor-dark-second);
    height: 22px;
}

.analyticsTransactionsExchangeInfo {
    margin: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.analyticsTransactionsExchangeInfo-dark {
    margin: 0;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.analyticsMarginList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
}

.analyticsMarginItem {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: var(--backgroundColor-light-second);
    padding: 24px;
}

.analyticsMarginItem-dark {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: var(--backgroundColor-dark-second);
    padding: 24px;
}

.analyticsMarginHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.analyticsMarginImg {
    width: 18px;
    height: 18px;
}

#marginLeftAnalyticsText {
    margin: 0 0 0 8px;
}

#notFirstAnalyticsMarginItem {
    margin-top: 16px;
}

.analyticsDivider {
    margin-top: 12px;
    background: var(--backgroundColor-light-third);
}

.analyticsDivider-dark {
    margin-top: 12px;
    background: var(--backgroundColor-dark-third);
}

.analyticsNotExpandedMain {
    display: flex;
    flex-direction: column;
}

.analyticsMainContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.analyticsMarginAccNum {
    color: var(--text-color-grey);
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 16px;
}

.analyticsMarginAccNum-dark {
    color: var(--text-color-grey-second);
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 16px;
}

.analyticsMarginHeaderBottomContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.analyticsMarginHeaderText {
    color: var(--text-light-one);
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    line-height: 24px;
}

.analyticsMarginHeaderText-dark {
    color: var(--text-dark-one);
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    line-height: 24px;
}

.analyticsMarginLess {
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    color: var(--text-color-more);
}

.analyticsMarginMedium {
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    color: var(--text-color-medium);
}

.analyticsWaveContainer {
    margin-top: 16px;
    position: absolute;
    left: 0;
    right: 0;
    margin-bottom: 10px;
}

.analyticsWaveImage {
    width: 100%;
    height: 48px;
}

.Toastify__toast-container {
    margin-top: 5px !important;
}

.Toastify__progress-bar--success {
    --toastify-color-progress-success: linear-gradient(90deg, var(--gradient-color-start) 0%, var(--text-color-link) 100%);
}