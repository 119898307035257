.exchangeInput {
    outline: none;
    box-shadow: none;
    border: 0;
    width: calc(100% - 115px);
    background-color: var(--backgroundColor-light-second);

    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangeInput-dark {
    outline: none;
    box-shadow: none;
    border: 0;
    width: calc(100% - 115px);
    background-color: var(--backgroundColor-dark-second);

    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangeInput-dark::placeholder {
    color: var(--text-color-grey-second);
    opacity: 0.4;
}

.exchangeInput::placeholder {
    color: var(--text-color-grey);
    opacity: 0.4;
}

.exchangeInputError {
    outline: none;
    box-shadow: none;
    border: 0;
    width: calc(100% - 115px);
    background-color: var(--backgroundColor-light-second);

    color: var(--text-color-error);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangeInputError-dark {
    outline: none;
    box-shadow: none;
    border: 0;
    width: calc(100% - 115px);
    background-color: var(--backgroundColor-dark-second);

    color: var(--text-color-error);
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.exchangeInputContainerFocused {
    height: 64px;
    padding: 15px 23px 15px 23px;
    background: linear-gradient(var(--backgroundColor-light-second) 0, var(--backgroundColor-light-second) ) padding-box,
                linear-gradient(90deg, var(--text-color-link) 0%, var(--text-color-link) 100%);
    border: 1px solid transparent;
    border-radius: 24px;
    margin-top: 24px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
}


.exchangeInputContainerFocused-dark {
    height: 64px;
    padding: 15px 23px 15px 23px;
    background: linear-gradient(var(--backgroundColor-dark-second) 0, var(--backgroundColor-dark-second) ) padding-box,
    linear-gradient(90deg, var(--text-color-link) 0%, var(--text-color-link) 100%);
    border: 1px solid transparent;
    border-radius: 24px;
    margin-top: 24px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
}

.exchangeInputContainer {
    height: 64px;
    padding: 16px 24px 16px 24px;
    background: var(--backgroundColor-light-second);
    border-radius: 24px;
    margin-top: 24px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
}

.exchangeInputContainer-dark {
    height: 64px;
    padding: 16px 24px 16px 24px;
    background: var(--backgroundColor-dark-second);
    border-radius: 24px;
    margin-top: 24px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
}

.inputHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.inputHeaderFirstElement {
    color: var(--text-color-grey);
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.inputHeaderFirstElement-dark {
    color: var(--text-color-grey-second);
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.inputHeaderAmountContainer {
    display: flex;
    flex-direction: row;
}

.inputHeaderSecondElement {
    margin: 0 5px 0 0;
    color: var(--text-color-grey);
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.inputHeaderSecondElement-dark {
    margin: 0 5px 0 0;
    color: var(--text-color-grey-second);
    text-align: right;
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.inputHeaderThirdElement {
    margin: 0;
    color: var(--text-light-one);
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

.inputHeaderThirdElement-dark {
    margin: 0;
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

.inputAssetIcon {
    width: 28px;
    height: 28px;
}

.withdrawInputAssetIcon {
    width: 20px;
    height: 20px;
}

.inputAssetText {
    color: var(--text-light-one);
    margin: 0 0 0 8px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}


.withdrawInputAssetText {
    font-family: Noto Sans, serif;
    margin: 0 0 0 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-light-one);
}

.withdrawInputAssetText-dark {
    font-family: Noto Sans, serif;
    margin: 0 0 0 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-dark-one);
}

.inputAssetText-dark {
    color: var(--text-dark-one);
    margin: 0 0 0 8px;
    font-family: Noto Sans, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
}

.inputMain {
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputMainAssetContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.inputBottomErrorText {
    color: var(--text-color-error);
    text-align: left;
    margin: 0;
    font-family: Noto Sans, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}