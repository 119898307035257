body {
    margin: 0;
}

@font-face {
    font-family: Noto Sans;
    src: url("../../public/NotoSans.ttf");
}

:root {
    --white: #FFFFFF;
    --backgroundColor-light-one: "";
    --backgroundColor-light-second: #F6F6F8;
    --backgroundColor-light-third: #EBECEE;
    --backgroundColor-dark-one: #141414;
    --backgroundColor-dark-second: #1F1F1F;
    --backgroundColor-dark-third: #303030;
    --backgroundColor-ripple: #f2f2f2;
    --backgroundColor-grey: #b0b0b0;
    --backgroundColor-button-registration: #1976d2;
    --gradient-color-start: #0161A4;
    --text-color-link: #00BEB4;
    --text-color-error: #E74C3C;
    --text-color-more: #D02B21;
    --text-color-medium: #E7B10A;
    --text-color-pending: #F39C12;
    --text-color-dark: #000000;
    --text-color-available: #06205E;
    --text-color-resend: #0159A2;
    --text-light-one: #181A21;
    --text-dark-one: #FEFEFE;
    --text-color-grey: #848790;
    --text-color-grey-second: #9C9C9D;
    --text-light-grey-third: #849AA8;
}

#root {
    background: var(--backgroundColor-dark-one);
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow: auto;
}

a {
    color: var(--text-color-link) !important;
}

.wrapper {
    text-align: center;
}

.vector-position {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: -75px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:valid,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    color: var(--tg-theme-text-color) !important;
    font-family: Noto Sans, serif !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding-bottom: 2px !important;
    -webkit-text-fill-color: var(--tg-theme-text-color) !important;
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}