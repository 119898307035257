.internal-modal-content {
    overflow: auto;
    height: 100%;
}

.internalInputContainer {
    margin-top: 24px;
}

.internalSwapContainer {
    position: absolute;
    width: calc(100% - 48px);
    top: 112px;
    z-index: 2;
}

.internalModalComponent {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70%;
    margin-top: 48px;
    background-color: var(--white);
    border-radius: 24px 24px 0 0;
    padding: 24px 24px 0 24px;
}

.internalModalComponent-dark {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70%;
    margin-top: 48px;
    background-color: var(--backgroundColor-dark-one);
    border-radius: 24px 24px 0 0;
    padding: 24px 24px 0 24px;
}

.internalModalItem {
    width: 100%;
    height: 44px;
    padding: 14px 0;
    display: flex;
    align-items: center;
}

.internalModalItemText {
    color: var(--text-light-one);
    margin: 0;
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.internalModalItemText-dark {
    color: var(--text-dark-one);
    margin: 0;
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.internalModalItemTextDisabled {
    color: var(--backgroundColor-light-third);
    margin: 0;
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.internalModalItemTextDisabled-dark {
    color: var(--text-color-grey-second);
    margin: 0;
    text-align: left;
    font-family: Noto Sans, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.internalAvailableContainer {
    margin-top: 4px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.internalAvailableText {
    text-align: left;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 16px;
}

.withdrawAvailableText {
    text-align: left;
    color: var(--text-color-grey);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.withdrawAvailableText-dark {
    text-align: left;
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.internalAvailableText-dark {
    text-align: left;
    color: var(--text-color-grey-second);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 16px;
}

.internalAvailableAmount {
    color: var(--text-color-available);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 6px;
}

.internalAvailableAmount-dark {
    color: var(--text-dark-one);
    font-family: Noto Sans, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 0 6px;
}