.statementConfirmContainer {
    background: var(--backgroundColor-light-second);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding: 16px 24px;
}

.statementConfirmContainer-dark {
    background: var(--backgroundColor-dark-second);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding: 16px 24px;
}

.statementConfirmFirstBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.statementConfirmSecondBlock {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.statementIcon {
    width: 24px;
    height: 24px;
}

.statementRadioText {
    font-family: 'Noto Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 0 15px;
    color: var(--text-light-one);
}

.statementRadioText-dark {
    font-family: 'Noto Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 0 15px;
    color: var(--text-dark-one);
}

.statementEmailContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin: 8px 0 0 0;
    text-align: left;
}